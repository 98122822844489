<!-- 兑率 -->
<template>
  <div id="ExchangeRate">
    <van-nav-bar
      :title="$t('m_header.dl')"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="warp">
      <h3 class="title">{{ $t("m_exchangeRate.hbdl") }}</h3>
      <ul class="ExchangeRateList">
        <li v-for="(item, index) in list" :key="index">
          <p class="nationalType">
            <img
              :src="configUrl + item.icon"
              lazy="loaded"
            />
            {{item.jiancheng}}
          </p>
          <p class="money">{{item.pt_huilv}}</p>
        </li>
        <!---->
      </ul>
    </div>
  </div>
</template>

<script>
import { getRate } from "@/api/home";
export default {
  name: "",
  data() {
    return {
      list: [],
      configUrl: process.env.VUE_APP_BASE_API
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.getRateShow();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    /* 查询频率 */
    getRateShow() {
      getRate().then((res) => {
        console.log(res);
        this.list = res.data;
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}

#ExchangeRate {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #404040;

  .warp {
    flex: 1;
  }

  .title {
    height: 1.1rem;
    background-color: #222222;
    padding: 0 0.1rem;
    color: #dfc6a0;
    font-size: 0.26rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .ExchangeRateList {
    width: 100%;
    margin-top: 0.15rem;
    background-color: #222222;
    padding: 0 0.2rem;
    box-sizing: border-box;

    li {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #404040;
      padding: 0.3rem 0.1rem;
      box-sizing: border-box;

      .nationalType {
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 0.3rem;

        img {
          width: 1.2rem;
          height: auto;
          margin-right: 0.15rem;
        }
      }

      .money {
        color: #d5b377;
        font-size: 0.4rem;
      }
    }
  }
}

/* @import url(); 引入css类 */
</style>